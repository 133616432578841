import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import Link from "../components/link";
import Button from "react-bootstrap/Button";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import SVGFBBlog from "../components/SVG/flyinf-bee-blog";

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
	const nextLink = next ? `/blog/${next?.slug} ` : "/blog";
	const prevLink = previous ? `/blog/${previous?.slug}` : "/blog";
	if (typeof window !== "undefined") {
		return (
			<Layout>
				<div>
					<Button
						as={Link}
						to={nextLink}
						style={{
							boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
							borderRadius: "20px 0px 0px 20px",
						}}
						className="position-fixed end-0 d-none d-md-block top-50 px-3 py-6 translate-middle-y"
					>
						<MdArrowForwardIos className="fs-3" />
					</Button>
					<Button
						as={Link}
						to={prevLink}
						style={{
							boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
							borderRadius: "0px 20px 20px 0px",
						}}
						className="position-fixed d-none d-md-block start-0 top-50 px-3 py-6 translate-middle-y"
					>
						<MdArrowBackIos className="fs-3" />
					</Button>

					<Container
						style={{ zIndex: 1 }}
						className="pb-lg-10 pt-lg-7 py-5 position-relative"
					>
						<SVGFBBlog
							style={{ zIndex: -2, right: "-5%" }}
							className="position-absolute d-none d-lg-block  top-50"
						/>{" "}
						<GatsbySeo
							title={`${post.title} | Hive HR Blog`}
							description={parse(post.excerpt)}
							language="en"
							openGraph={{
								title: `${post.title}`,
								description: `${parse(post.excerpt)}`,
								url: ``,
								type: "article",
								images: [
									{
										url: `${post.blogFields.featureImage.localFile.publicURL}`,
									},
								],
							}}
						/>
						<Row className="justify-content-center mt-xl-5">
							<Col xs={12} md={11} lg={10}>
								<div className="shadow bg-white py-4 px-md-5 px-3">
									<header className="w-80">
										<h4 className="text-bgGrey">Blog</h4>
										<h1 className="mb-4 display-5">{parse(post.title)}</h1>
										<hr className="hr-primary  w-70 mb-2" />
									</header>
									<p className="mb-4 fw-normal">{post.blogFields.date}</p>

									{!!post.content && (
										<section className="blog-post">
											{parse(post.content)}
										</section>
									)}
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</Layout>
		);
	}
};

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostById(
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		post: wpPost(id: { eq: $id }) {
			id
			excerpt
			content
			title
			date(formatString: "MMMM DD, YYYY")
			blogFields {
				date
				featureImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							gatsbyImageData(
								quality: 100
								placeholder: BLURRED
								layout: FULL_WIDTH
							)
						}
					}
				}
			}
		}
		previous: wpPost(id: { eq: $previousPostId }) {
			slug
			title
		}
		next: wpPost(id: { eq: $nextPostId }) {
			slug
			title
		}
	}
`;
